module.exports = [{
      plugin: require('../../../.yarn/__virtual__/gatsby-plugin-material-ui-virtual-19c2263431/4/buildhome/.yarn_cache/gatsby-plugin-material-ui-npm-4.1.0-2938ee1c08-f1d38d2e50.zip/node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[],"pathToEmotionCacheProps":"src/emotion-cache-props"},
    },{
      plugin: require('../../../../../../buildhome/.yarn_cache/gatsby-plugin-web-font-loader-npm-1.0.4-e6329b179e-7cb4df8ed5.zip/node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"google":{"families":["Cabin","Lato","Cabin:bold","Lato:bold","Inter:bold"]}},
    },{
      plugin: require('../../../.yarn/__virtual__/gatsby-plugin-manifest-virtual-4ab7ee306b/4/buildhome/.yarn_cache/gatsby-plugin-manifest-npm-4.19.0-5c73f87aea-bc47920497.zip/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Flock","short_name":"Flock","start_url":"/","background_color":"#367DE8","theme_color":"#367DE8","display":"minimal-ui","icon":"src/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"11ac6296d555b5dccd62098791e0d970"},
    },{
      plugin: require('../../../.yarn/__virtual__/gatsby-plugin-segment-js-virtual-068b8066a7/4/buildhome/.yarn_cache/gatsby-plugin-segment-js-npm-3.7.1-69054e5b34-801e02268b.zip/node_modules/gatsby-plugin-segment-js/gatsby-browser.js'),
      options: {"plugins":[],"prodKey":"Y0g2LtIcZU0og9toxyl0jQdWFhrFwwif","devKey":"Y0g2LtIcZU0og9toxyl0jQdWFhrFwwif","trackPage":false},
    },{
      plugin: require('../../../.yarn/__virtual__/@sentry-gatsby-virtual-529ea5d36d/4/buildhome/.yarn_cache/@sentry-gatsby-npm-6.19.7-98559ac9b4-bf2192507c.zip/node_modules/@sentry/gatsby/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://cb5d728ad4a44734bced51daa20f0ca8@o925641.ingest.sentry.io/5890043","sampleRate":1,"environment":"production","release":"547feb4cf","ignoreErrors":["Invalid state"]},
    },{
      plugin: require('../../../.yarn/__virtual__/gatsby-plugin-splitio-virtual-0f4a6bffc5/4/buildhome/.yarn_cache/gatsby-plugin-splitio-npm-0.1.1-0f4119d00c-e42e9ac0ac.zip/node_modules/gatsby-plugin-splitio/gatsby-browser.js'),
      options: {"plugins":[],"authorizationKey":"4rtuk6o17rv2acaesqce5jvktbid5ahbrvsq","key":"15066370-99c5-11ec-80ec-361795b95a8a"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../.yarn/unplugged/gatsby-virtual-25598fae33/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
